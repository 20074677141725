import React, { useState } from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';

import './scss/style.scss';

import Nav from './Nav';
import Header from './Header';
import Work from './Work';
import About from './About';
import Clients from './Clients';
import Contact from './Contact';

function App() {

  const [showMenu, setShowMenu] = useState(false);
  const showMenuToggle = () => setShowMenu(!showMenu);

  return (
    <Router>
      <main className={showMenu ? 'menuOpen' : 'menuClosed'}>

        <button onClick={showMenuToggle} className="menuToggle">
          <span></span>
          <span></span>
        </button>

        {/* <NavLink className="homeButton" to="/" exact onClick={() => setShowMenu(false)}>
          <svg viewBox="0 0 133.74 128.49">
            <path d="M132.24,62.13,70.37,1.43a5,5,0,0,0-7,0L1.5,62.13A5,5,0,0,0,0,65.7v62.79H10V67.8L66.87,12l56.87,55.8v60.69h10V65.7A5,5,0,0,0,132.24,62.13Z" fill="#231f20" />
          </svg>
        </NavLink> */}

        <CSSTransition
          in={true}
          timeout={2000}
          onEnter={() => setShowMenu(true)}
          onExited={() => setShowMenu(false)}
        >
          <Nav>
            <NavLink to="/about" onClick={() => setShowMenu(false)}>about</NavLink>
            <NavLink to="/work" onClick={() => setShowMenu(false)}>work</NavLink>
            <NavLink to="/clients" onClick={() => setShowMenu(false)}>clients</NavLink>
            <NavLink to="/contact" onClick={() => setShowMenu(false)}>contact</NavLink>
          </Nav>

        </CSSTransition>

        <Route path="/" exact component={Header} />
        <Route path="/about" component={About} />
        <Route path="/work" component={Work} />
        <Route path="/clients" component={Clients} />
        <Route path="/contact" component={Contact} />

      </main >
    </Router >
  );
}

export default App;