import React, { useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Shapes from './Shapes';
import Footer from './Footer';

const Contact = () => {

  const [animateShapes, setAnimateShapes] = useState(false);

  return (
    <section id="contact">
      <h1 className="visuallyHidden">Contact</h1>

      <CSSTransition
        classNames={{
          appear: 'appear',
          enterDone: 'enter-done',
        }}
        appear={true}
        in={true}
        timeout={3000}
        onEnter={() => setAnimateShapes(true)}
        onExited={() => animateShapes(false)}
        unmountOnExit
      >

        <div>
          <svg className="siteLogo" viewBox="0 0 566.9 306.7">
            <title>Marc Richard Logo</title>
            <path d="M299.6,119.7V306.6H266.8V119.7c0-33.5-8.4-58.9-24.4-73.4-10.4-9.4-20.7-11.2-20.8-11.2-.5-.1-.9-.1-1.4-.2-34.1-7.3-68.6,5.9-102.7,39l-23-23.6C136.2,9.8,181.8-6.6,226.4,2.7c4.4.7,21.3,4.3,37.9,19.4C293.4,48.4,299.6,89.7,299.6,119.7ZM490,22C473.4,6.9,456.5,3.4,452.1,2.7c-44.6-9.3-90.2,7.1-131.9,47.6l22.9,23.5c34.1-33.1,68.6-46.2,102.7-39a6.75,6.75,0,0,0,1.4.2c.1,0,10.4,1.8,20.8,11.2,16,14.5,24.4,39.8,24.4,73.4V306.5h32.8V119.7C525.2,89.7,519.1,48.4,490,22ZM0,32.8H41.7V306.5H74.5V0H0Z" fill="#010101" />
          </svg>

          <div className="animated-wrapper">
            <Shapes />
          </div>

          <Footer />
        </div>

      </CSSTransition>

    </section>
  )
}

export default Contact;