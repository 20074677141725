import React from 'react';

const Work = () => {
  return (
    <section id="work">
      <h1 className="visuallyHidden">Work</h1>
      <figure className="video-wrapper">
        <iframe title="Marc Richard's showreel" src="https://player.vimeo.com/video/447792166" width="960" height="540" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
      </figure>
    </section >
  );
}
export default Work;