import React, { Fragment } from 'react';

class Shapes extends React.Component {
  render() {

    let shuffleArray = function (array) {
      for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1));
        let temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
      return array;
    };

    let colourArray = ['#ff7f00', '#ffd217', '#b90054', '#5BB7BE'];

    let shapeArray = [
      '<title>Triangle</title><polygon points="210.62 198.98 125.58 51.69 125.58 51.69 40.54 198.98 210.62 198.98" />',
      '<title>Square</title><rect x="40.64" y="39.93" width="170.08" height="170.08" />',
      '<title>Circle</title><rect x="40.54" y="40.52" width="170.08" height="170.08" rx="84.65" />'
    ];

    shuffleArray(colourArray);
    shuffleArray(shapeArray)

    return (
      <Fragment>
        <figure role="presentation" className="shape-wrapper">
          <svg className="shape" viewBox="0 0 255.12 255.12" fill={colourArray[0]} dangerouslySetInnerHTML={{ __html: shapeArray[0] }}></svg>
          <svg className="shape" viewBox="0 0 255.12 255.12" fill={colourArray[1]} dangerouslySetInnerHTML={{ __html: shapeArray[1] }}></svg>
          <svg className="shape" viewBox="0 0 255.12 255.12" fill={colourArray[2]} dangerouslySetInnerHTML={{ __html: shapeArray[2] }}></svg>
        </figure>

        <figure role="presentation" className="symbol-wrapper">
          <svg className="symbol" viewBox="0 0 255.12 255.12">
            <title>Waves</title>
            <path d="M203.75,163.42a6.52,6.52,0,0,0-5.12-2.39c-2.57,0-4,1.46-5.13,2.38a3.24,3.24,0,0,1-2.57,1.28,3.2,3.2,0,0,1-2.56-1.28,6.91,6.91,0,0,0-5.14-2.38c-2.57,0-4,1.47-5.13,2.39a3.53,3.53,0,0,1-2.57,1.28,3.2,3.2,0,0,1-2.56-1.28,6.53,6.53,0,0,0-5.13-2.39v3.66A3.2,3.2,0,0,1,170.4,166a6.51,6.51,0,0,0,5.13,2.38c2.57,0,4-1.46,5.13-2.38a3.21,3.21,0,0,1,5.13,0,6.51,6.51,0,0,0,5.13,2.38c2.56,0,4-1.46,5.13-2.38a3.21,3.21,0,0,1,5.13,0,6.51,6.51,0,0,0,5.13,2.38V164.7A3.2,3.2,0,0,1,203.75,163.42Z" />
            <path d="M198.63,183c-2.57,0-4,1.47-5.13,2.38a3.21,3.21,0,0,1-2.57,1.28,3.17,3.17,0,0,1-2.56-1.28,6.84,6.84,0,0,0-5.13-2.38c-2.57,0-4,1.47-5.13,2.38a3.53,3.53,0,0,1-2.58,1.28A3.17,3.17,0,0,1,173,185.4a6.53,6.53,0,0,0-5.13-2.38v3.66A3.18,3.18,0,0,1,170.4,188a6.54,6.54,0,0,0,5.13,2.38c2.57,0,4-1.47,5.13-2.38a3.2,3.2,0,0,1,5.13,0,6.54,6.54,0,0,0,5.13,2.38c2.56,0,4-1.47,5.13-2.38a3.2,3.2,0,0,1,5.13,0,6.54,6.54,0,0,0,5.13,2.38v-3.67a3.17,3.17,0,0,1-2.56-1.28A6.52,6.52,0,0,0,198.63,183Z" />
            <path d="M198.63,175.69c-2.57,0-4,1.47-5.13,2.38a3.24,3.24,0,0,1-2.58,1.28,3.2,3.2,0,0,1-2.56-1.28,6.84,6.84,0,0,0-5.13-2.39c-2.57,0-4,1.47-5.13,2.39a3.49,3.49,0,0,1-2.57,1.28,3.2,3.2,0,0,1-2.56-1.28,6.53,6.53,0,0,0-5.13-2.38v3.66a3.2,3.2,0,0,1,2.56,1.28,6.51,6.51,0,0,0,5.13,2.38c2.57,0,4-1.46,5.13-2.38a3.21,3.21,0,0,1,5.13,0,6.51,6.51,0,0,0,5.13,2.38c2.56,0,4-1.46,5.13-2.38a3.21,3.21,0,0,1,5.13,0,6.51,6.51,0,0,0,5.13,2.38v-3.66a3.2,3.2,0,0,1-2.56-1.28A6.52,6.52,0,0,0,198.63,175.69Z" />
            <path d="M198.63,168.36c-2.57,0-4,1.47-5.13,2.38a3.22,3.22,0,0,1-2.57,1.29,3.18,3.18,0,0,1-2.56-1.29,6.84,6.84,0,0,0-5.13-2.38c-2.57,0-4.05,1.47-5.14,2.39a3.5,3.5,0,0,1-2.57,1.29,3.21,3.21,0,0,1-2.56-1.29,6.5,6.5,0,0,0-5.13-2.39V172a3.17,3.17,0,0,1,2.56,1.28,6.51,6.51,0,0,0,5.13,2.38c2.57,0,4-1.47,5.13-2.38a3.21,3.21,0,0,1,2.57-1.28,3.17,3.17,0,0,1,2.56,1.28,6.51,6.51,0,0,0,5.13,2.38c2.56,0,4-1.47,5.13-2.38a3.21,3.21,0,0,1,5.13,0,6.51,6.51,0,0,0,5.13,2.38V172a3.18,3.18,0,0,1-2.56-1.29A6.52,6.52,0,0,0,198.63,168.36Z" />
          </svg>

          <svg className="symbol" viewBox="0 0 255.12 255.12">
            <title>Cross</title>
            <polygon points="23.63 189.62 29.07 195.06 42.63 181.49 56.2 195.06 61.66 189.62 48.09 176.05 61.66 162.48 56.2 157.03 42.63 170.6 29.07 157.03 23.63 162.48 37.2 176.05 23.63 189.62" />
          </svg>

          <svg className="symbol" viewBox="0 0 255.12 255.12">
            <title>Infinity</title>
            <path d="M214.13,170.71a13.64,13.64,0,0,0-12.61-8.36,12.6,12.6,0,0,0-2.81.31,14.43,14.43,0,0,0-4.61,2,22.4,22.4,0,0,0-3.36,2.71,42.64,42.64,0,0,0-3,3.2l-.89,1.08c-.38-.48-.77-.95-1.16-1.42-.87-1-1.77-2-2.72-2.91a25.74,25.74,0,0,0-2.25-1.93l-.46-.33a15.87,15.87,0,0,0-3.44-1.86,13.27,13.27,0,0,0-3.69-.83h-.91a13.71,13.71,0,0,0-.42,27.42h.42a12.58,12.58,0,0,0,2.8-.31,14.54,14.54,0,0,0,4.65-2,22.66,22.66,0,0,0,3.37-2.72,42.36,42.36,0,0,0,2.95-3.19l.88-1.11c.39.48.77,1,1.16,1.43.87,1,1.77,2,2.72,2.9l.55.51c.55.5,1.12,1,1.71,1.42a16.49,16.49,0,0,0,3.9,2.21l.27.09a12.54,12.54,0,0,0,4.33.76,13.73,13.73,0,0,0,12.62-19Zm-31.61,6.43-.24.29a37.91,37.91,0,0,1-2.53,2.84,19.4,19.4,0,0,1-1.9,1.72,11.34,11.34,0,0,1-2.78,1.7,7.36,7.36,0,0,1-1.38.43,7.07,7.07,0,0,1-1.45.15,8.23,8.23,0,0,1,0-16.46,7,7,0,0,1,1.59.18,8.7,8.7,0,0,1,2.66,1.14,15.64,15.64,0,0,1,2,1.53,30.85,30.85,0,0,1,3.07,3.19c.59.66,1.18,1.43,1.78,2.19Zm26.57,2.11A8.26,8.26,0,0,1,204,183.9a8.45,8.45,0,0,1-2.45.36,7,7,0,0,1-1.58-.18,8.4,8.4,0,0,1-2-.75c-.22-.12-.45-.24-.66-.38a15.08,15.08,0,0,1-2-1.53c-.25-.22-.51-.46-.76-.71a33.59,33.59,0,0,1-2.3-2.48c-.6-.67-1.19-1.44-1.79-2.19l.88-1.1.23-.29c.85-1,1.69-2,2.53-2.85a23.67,23.67,0,0,1,1.88-1.72,12,12,0,0,1,2.78-1.7,8.26,8.26,0,0,1,1.39-.42,6.9,6.9,0,0,1,1.44-.15,8.23,8.23,0,0,1,7.58,11.44Z" />
          </svg>
        </figure>
      </Fragment>
    )
  }
}

export default Shapes;