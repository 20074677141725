import React from 'react';

const Footer = () => {

  return (
    <footer>
      <div className="social">
        <a href="mailto:mail@marcrichard.co.uk" title="Mail">
          <svg viewBox="0 0 255.12 255.12">
            <title>Mail</title>
            <path d="M77.1,98l33.2,29.1c0.3,0.3,0.3,0.8,0,1.1l-33.2,29.1c-0.5,0.4-1.2,0.1-1.2-0.5V98.5  C75.9,97.9,76.7,97.5,77.1,98z M127.1,134.1L82.2,94.6c-0.5-0.4-0.2-1.3,0.5-1.3h89.8c0.7,0,1,0.8,0.5,1.3L128,134.1  C127.8,134.3,127.4,134.3,127.1,134.1z M114.8,131.8c0.3-0.2,0.7-0.2,1,0l9.9,8.7c1.1,0.9,2.7,0.9,3.8,0l9.9-8.7  c0.3-0.2,0.7-0.2,1,0l32.7,28.6c0.5,0.4,0.2,1.3-0.5,1.3H82.6c-0.7,0-1-0.8-0.5-1.3L114.8,131.8z M144.8,127L178,98  c0.5-0.4,1.2-0.1,1.2,0.5v58.2c0,0.6-0.7,1-1.2,0.5l-33.2-29.1C144.5,127.8,144.5,127.3,144.8,127z" />
          </svg>
        </a>

        <a href="https://linkedin.com/in/mrcouk" title="LinkedIn">
          <svg viewBox="0 0 255.12 255.12">
            <title>Linked In</title>
            <path d="M133.22,114.94a8.67,8.67,0,0,0,.65-.71c5.32-7.77,12.92-10,21.8-8.69,10.25,1.58,16.81,8.76,18.48,19.74a50.33,50.33,0,0,1,.59,7.85v32.79c0,1.23-.3,1.59-1.56,1.58q-8.6-.1-17.18,0c-1.26,0-1.58-.35-1.57-1.58q.06-15.28,0-30.58a24.49,24.49,0,0,0-.82-6.87,9.88,9.88,0,0,0-12-7.08,9.67,9.67,0,0,0-4.06,2.15c-3.15,2.58-4.38,6-4.36,10,0,9.62,0,19.23,0,28.85,0,1.26-.07,2.53,0,3.78.08,1.09-.37,1.3-1.36,1.3-5.83,0-11.66-.05-17.5,0-1.06,0-1.44-.21-1.42-1.37q.12-13,.12-26,0-16-.09-32c0-1.16.23-1.51,1.44-1.5,5.83.07,11.67.06,17.5,0,1,0,1.34.31,1.31,1.33C133.17,110.14,133.22,112.34,133.22,114.94Zm-31.34-6.88c0-1-.17-1.47-1.35-1.45-5.88.06-11.76,0-17.65,0-1.06,0-1.3.33-1.3,1.34V166.1c0,1.13.36,1.4,1.44,1.39,5.77-.05,11.55-.07,17.33,0,1.3,0,1.54-.41,1.54-1.6V137.05C101.89,127.44,101.85,117.73,101.88,108.06ZM81.14,83.82a10.4,10.4,0,0,0,8.2,14.35c6.49,1.06,12.11-2.07,13.5-7.51,1.84-7.2-3.2-13.34-11-13.35C86.78,77.3,82.72,79.76,81.14,83.82Z" fillRule="evenodd" />
          </svg>
        </a>
      </div>
    </footer >
  )
}

export default Footer;