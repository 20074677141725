import React from 'react';
import { withRouter } from 'react-router-dom';

class Nav extends React.Component {
  render() {
    return (
      <nav>
        {this.props.children}
      </nav>
    )
  }
}

export default withRouter(Nav);