import React, { Fragment } from 'react';
import TrackVisibility from 'react-on-screen';
import Profile from './images/headshot.jpg';

const ComponentToTrack = ({ isVisible }) => {

  return (
    <Fragment>
      <aside className={isVisible ? 'profileAside-image in-view' : 'profileAside-image not-in-view'}>
        <figure>
          <div role="presentation" className="profileImage back">
            <svg viewBox="0 0 255.12 255.12"><title>Marc Richard Logo</title>
              <path d="M128,93a20.32,20.32,0,0,0-10-5.16c-11.75-2.45-23.76,1.88-34.72,12.54l6,6.31c9-8.7,18.07-12.17,27-10.25h.35a12.1,12.1,0,0,1,5.8,3.29c4,3.85,6.07,10.42,6.07,19v49.22h8.7V118.7C137.3,110.81,135.7,99.93,128,93Zm59.45,0a20.29,20.29,0,0,0-10.06-5.16c-11.76-2.45-23.75,1.88-34.73,12.54l6,6.19c9-8.7,18.06-12.16,27-10.25h.34a12,12,0,0,1,5.8,3.29c4,3.86,6.09,10.43,6.09,19V167.8h8.7V118.7c0-7.89-1.57-18.77-9.24-25.71ZM58.43,95.89h11V168h8.71V87.19H58.43Z" fill="#fff" />
            </svg>
          </div>
          <img alt="Marc Richards smiling, in the background his logo" className="profileImage" src={Profile} />
        </figure>
        <a href="https://www.dropbox.com/s/rswz2xd6f8h4m9a/MR%20CV.pdf?dl=0" download>[Download CV]</a>
        <a href="mailto:mail@marcrichard.co.uk">mail@marcrichard.co.uk</a>
      </aside>

      <aside className="profileAside-content">
        <h1>Marc Richard</h1>
        <p>I come from an early print and packaging background whilst studying graphic communication with typography, working from London and previously the Middle East on wayfinding, information and environmental design before most recently transitioning into presentation design.</p>
        <p>My past practice in typography and information design allows me to craft messages in a refined considered way, ensuring content is concise but still accessible to all and ultimately: exciting, original and beautiful!</p>
      </aside>
    </Fragment>
  )
}

const About = () => {
  return (
    <section id="about">
      <TrackVisibility className="column-wrapper" partialVisibility>
        <ComponentToTrack />
      </TrackVisibility>
    </section>
  );
}
export default About;
